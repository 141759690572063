import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/study/self-evaluation/question-bank-list',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal-center',
    name: 'Personal',
    meta: {
      title: t('router.personal'),
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: 'personal-center',
        component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
        name: 'PersonalCenter',
        meta: {
          title: t('router.personalCenter'),
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/common',
    component: Layout,
    name: 'Common',
    redirect: '/common/dictionaryList',
    meta: {
      title: '通用',
      icon: 'vi-ant-design:dashboard-filled',
      alwaysShow: true
    },
    children: [
      {
        path: 'dictionaryList',
        component: () => import('@/views/PvDictionaries/DictionaryList/DictionaryList.vue'),
        name: 'DictionaryList',
        meta: {
          title: '字典管理',
          noCache: true
        }
      },
      {
        path: 'tree-list',
        component: () => import('@/views/PvTree/TreeList.vue'),
        name: 'TreeList',
        meta: {
          title: '树管理',
          noCache: true
        }
      },
      {
        path: 'consumer-list',
        component: () => import('@/views/PvConsumer/ConsumerList.vue'),
        name: 'ConsumerList',
        meta: {
          title: '用户管理',
          noCache: true
        }
      },
      {
        path: 'suggest-list',
        component: () => import('@/views/PvSuggest/PvSuggestList.vue'),
        name: 'PvSuggestList',
        meta: {
          title: '评论管理',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/study',
    component: Layout,
    name: 'Study',
    redirect: '/study/course',
    meta: {
      orderNo: 10,
      icon: 'ion:book-outline',
      title: '学习中心'
    },
    children: [
      {
        path: 'banner-list',
        component: () => import('@/views/PvBanner/BannerList.vue'),
        name: 'BannerList',
        meta: {
          title: '轮播图管理',
          noCache: true
        }
      },
      {
        path: 'self-evaluation',
        component: getParentLayout(),
        redirect: '/study/self-evaluation/question-bank-list',
        name: 'SelfEvaluation',
        meta: {
          title: '能力验证'
        },
        children: [
          {
            path: 'question-bank-list',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/QuestionBankList.vue'),
            name: 'QuestionBankList',
            meta: {
              title: '试题库管理',
              noCache: true
            }
          },
          {
            path: 'single-choice',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/SingleChoice.vue'),
            name: 'single-choice',
            meta: {
              title: '单选题创编',
              noCache: true,
              hidden: true,
              activeMenu: '/study/self-evaluation/question-bank-list'
            }
          },
          {
            path: 'multiple-choice',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/MultipleChoice.vue'),
            name: 'MultipleChoice',
            meta: {
              title: '多选题创编',
              noCache: true,
              hidden: true,
              activeMenu: '/study/self-evaluation/question-bank-list'
            }
          },
          {
            path: 'mock-list',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamMockList.vue'),
            name: 'ExamMockList',
            meta: {
              title: '模拟考试',
              noCache: true
            }
          },
          {
            path: 'mock-create',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamMockCreate.vue'),
            name: 'ExamMockCreate',
            meta: {
              title: '模考创编',
              noCache: true,
              hidden: true,
              activeMenu: '/study/self-evaluation/mock-list'
            }
          },
          {
            path: 'mock-result-info',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutInfo.vue'),
            name: 'ExamMockResutInfo',
            meta: {
              title: '答卷信息',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/mock-list'
            }
          },
          {
            path: 'mock-result-detail',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutIDetail.vue'),
            name: 'ExamMockResutIDetail',
            meta: {
              title: '答卷详情',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/mock-list'
            }
          },
          {
            path: 'mock-result-static',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutStatic.vue'),
            name: 'ExamMockResutStatic',
            meta: {
              title: '答卷统计',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/mock-list'
            }
          },
          {
            path: 'online-list',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamOnlineList.vue'),
            name: 'ExamOnlineList',
            meta: {
              title: '在线考试',
              noCache: true
            }
          },
          {
            path: 'create',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamOnlineCreate.vue'),
            name: 'ExamOnlineCreate',
            meta: {
              title: '考试创编',
              noCache: true,
              hidden: true,
              activeMenu: '/study/self-evaluation/online-list'
            }
          },
          {
            path: 'result-info',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutInfo.vue'),
            name: 'ExamResutInfo',
            meta: {
              title: '答卷信息',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/online-list'
            }
          },
          {
            path: 'result-detail',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutIDetail.vue'),
            name: 'ExamResutIDetail',
            meta: {
              title: '答卷详情',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/online-list'
            }
          },
          {
            path: 'result-static',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutStatic.vue'),
            name: 'ExamResutStatic',
            meta: {
              title: '答卷统计',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/study/self-evaluation/online-list'
            }
          },
          {
            path: 'question-examine',
            component: () => import('@/views/PvSelfEvaluation/QuestionExamine/QuestionExamine.vue'),
            name: 'QuestionExamine',
            meta: {
              title: '试题征集审核',
              noCache: true
            }
          }
        ]
      },
      {
        path: 'knowledge-list',
        component: () => import('@/views/PvKnowledge/PvKnowledgeList.vue'),
        name: 'PvKnowledgeList',
        meta: {
          title: '知识库管理',
          noCache: true
        }
      },
      {
        path: 'course',
        component: getParentLayout(),
        meta: { title: '成长学堂' },
        redirect: '/study/course/course-list',
        name: 'Course',
        children: [
          {
            path: 'video-list',
            component: () => import('@/views/PvVideo/PvVideoList.vue'),
            name: 'PvVideoList',
            meta: {
              title: '大咖视频',
              noCache: true
            }
          },
          {
            path: 'course-list',
            component: () => import('@/views/PvCourse/CourseList.vue'),
            name: 'CourseList',
            meta: {
              title: '课程列表',
              noCache: true
            }
          },
          {
            path: 'course-add',
            component: () => import('@/views/PvCourse/AddCourse.vue'),
            name: 'CourseAdd',
            meta: {
              title: '课程创编',
              noCache: true,
              hidden: true
            }
          },
          {
            path: 'course-order',
            component: () => import('@/views/PvCourse/CourseOrder.vue'),
            name: 'CourseOrder',
            meta: {
              title: '课程订单',
              noCache: true
            }
          },
          {
            path: 'auth-code',
            component: () => import('@/views/PvCourse/AuthCode.vue'),
            name: 'AuthCode',
            meta: {
              title: '授权码',
              noCache: true,
              hidden: true
            }
          },
          {
            path: 'course-analysis',
            component: () => import('@/views/PvCourse/CourseAnalysis.vue'),
            name: 'CourseAnalysis',
            meta: {
              title: '课程分析',
              noCache: true,
              hidden: true
            }
          }
        ]
      },
      {
        path: 'certificate',
        component: getParentLayout,
        meta: { title: '证书管理' },
        name: 'Certificate',
        redirect: '/study/certificate/certificate-template',
        children: [
          {
            path: 'certificate-template',
            component: () => import('@/views/PvCertificate/PvCertificateTemplate.vue'),
            name: 'PvCertificateTemplate',
            meta: {
              title: '证书模板',
              noCache: true
            }
          },
          {
            path: 'certificate-templete',
            component: () => import('@/views/PvCertificate/PvCertificateList.vue'),
            name: 'PvCertificateList',
            meta: {
              title: '证书列表',
              noCache: true
            }
          }
        ]
      },
      {
        path: 'medical-nav',
        name: 'MedicalNav',
        component: getParentLayout(),
        redirect: '/study/medical-nav/management',
        meta: {
          title: '医药导航'
        },
        children: [
          {
            path: 'management',
            name: 'NavManagement',
            component: () => import('/@/views/study/medical-nav/management/index.vue'),
            meta: {
              title: '导航管理'
            }
          },
          {
            path: 'approval',
            name: 'NavApproval',
            component: () => import('/@/views/study/medical-nav/approval/index.vue'),
            meta: {
              title: '导航审批管理'
            }
          }
        ]
      }
    ]
  }
  // {
  //   path: '/expert',
  //   component: Layout,
  //   meta: {},
  //   name: 'Expert',
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/PvExpert/PvExpertList.vue'),
  //       name: 'ExpertList',
  //       meta: {
  //         title: '专家信息管理',
  //         noCache: true,
  //         icon: 'vi-ant-design:dashboard-filled'
  //       }
  //     }
  //   ]
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
